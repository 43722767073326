<template>
<div>
       <div class="row uexhead uexheader">
      <div class="col m10 s12">
        <span class="black-text text-darken-3" style="margin-left:-10px;"><strong>TEST ANALYSIS </strong></span>
        <span @click="Goback()" class="purple-text text-darken-3"><strong>/ DETAIL REPORT</strong></span>
      </div>
      <div class="col m2 s12"><a class="waves-effect waves-light btn uexbackbutton"
          @click="Goback()"><span class="Tiny material-icons"
            style="position: relative;top: 7px;margin-right: 2px;">arrow_back</span><strong>Go
            Back</strong></a></div>
    </div>
      <div class="uexdics divider"></div>
      <!-- <button class="but1 white-text" @click="agrows()">get rows</button> -->
       <div>
                    <!-- <button  @click="onBtnUpdate()">Show CSV export content text</button> -->
                    <button class="csvbut" @click="onBtnExport()">Download CSV export file</button>
       </div>
    <div class="ag-theme-uex">
           <ag-grid-vue
               style=" height: 536px"
               class="ag-theme-alpine ag-theme-uex"
               :columnDefs="columnDefs"
               :rowData="rowData"
                rowSelection = "multiple"
                @grid-ready="onGridReady"
                 :pagination="true"
                :paginationAutoPageSize="true"
           >
          </ag-grid-vue>
              <!-- <ag-grid-vue
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine  ag-theme-uex"
                :columnDefs="columnDefs"
                @grid-ready="onGridReady"
                :suppressExcelExport="true"
                :popupParent="popupParent"
                :pagination="true"
                :paginationAutoPageSize="true"
                :suppressExcelExport="true"
                :rowData="rowData"></ag-grid-vue> -->
 <!-- <textarea id="csvResult" style="height:600px;width:1220px;">Click the Show CSV export content button to view exported CSV here</textarea> -->
</div>
</div>
</template>

<script>
// import moment from "moment";
// import Vue from "vue";
// import "ag-grid-community/styles//ag-grid.css";
// import "ag-grid-community/styles//ag-theme-alpine.css";
// eslint-disable-next-line import/no-unresolved
import { AgGridVue } from "ag-grid-vue3";
import MobileAPI from "../Mobileapi";
// eslint-disable-next-line import/no-unresolved
// import LinkCellRenderer from "../components/LinkCellRenderer";

// Vue.filter("formatDate", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY");
//   }
//   return value;
// });
// Vue.filter("formatTime", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format(" hh:mm a");
//   }
//   return value;
// });
export default {
  name: "DetailReport",

  data() {
    return {
      gridApi: null,
      columnApi: null,
      leaderBg: [],
      Leaderboard: [
        { name: "Jaymin Shah", subject: "Maths" },
        { name: "Nikita Jani", subject: "Maths" },
        { name: "Krul Kulkarni", subject: "Maths" },
        { name: "Dhruvesh Desai", subject: "Maths" },
        { name: "Parul Jain", subject: "Maths" },
      ],
      columns: null,
      rows: null,
      // columnDefs: [
      //   {
      //     field: "Student name", sortable: true, filter: true,
      //   },
      //   {
      //     field: "Biology Objective 3 Attempted", sortable: true, filter: true,
      //   },
      //   {
      //     field: "TotalMarks", sortable: true, filter: true,
      //   },
      //   {
      //     field: "FinalRank", sortable: true, filter: true,
      //   },
      //   { field: "", width: 100 },
      //   { field: "", width: 100 },
      //   {
      //     field: "accuracy", width: 130, sortable: true, filter: true, valueFormatter: "Math.floor(value)",

      //   },
      // ],
      defaultColDef: {
        sortable: true,
      },
      columnDefs: [],
      rowData: [],
      getInstituteTestPartWiseUserExtensiveReport: [],
      instituteTestId: null,
      components: {
        // LinkCellRenderer,
      },
    };
  },
  // activated() {
  //   this.popupParent = document.body;
  //   this.columns = [
  //     {
  //       headerName: "Name", field: "name", sortable: true, filter: true, checkboxSelection: true,
  //  cellRendererFramework: "LinkCellRenderer", suppressMovable: true,hide:true,
  //     },
  //   ];
  // },
  activated() {
    // this.popupParent = document.body;
    this.instituteTestId = Number(this.$route.params.TestAnalysisTestId);
    // this.columnDefs = [];
    const data = {
      // InstituteTestId: 39092,
      // InstituteTestId: 39349,

      InstituteTestId: this.instituteTestId,
    };
    // console.log(data);
    this.$store.dispatch("showLoader", true);
    MobileAPI.getInstituteTestPartWiseUserExtensiveReport(data, (response) => {
      this.$store.dispatch("showLoader", false);
      this.getInstituteTestPartWiseUserExtensiveReport = response.data[0];
      Object.keys(this.getInstituteTestPartWiseUserExtensiveReport).forEach((key) => {
        if (key === "UserId") {
          this.columnDefs.push({
            field: key, hide: true,
          });
        } else if (key === "Student") {
          this.columnDefs.push({
            field: key,
            sortable: true,
            filter: true,
          });
        } else {
          this.columnDefs.push({
            field: key,
            sortable: true,
            filter: true,
            comparator: (valueA, valueB) => +valueA - +valueB,
          });
        }
      });
      this.rowData = response.data;
      // console.log(this.rowData);
      // console.log(this.columnDefs);
    });
  },
  created() {

  },
  methods: {
    onBtnExport() {
      this.gridApi.exportDataAsCsv();
    },
    onBtnUpdate() {
      document.querySelector("#csvResult").value = this.gridApi.getDataAsCsv();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    roundOf(num) {
      return Math.floor(num);
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    Goback() {
      this.$router.push({
        name: "TestAnalysis",
        params: {
        },
      });
      this.getInstituteTestPartWiseUserExtensiveReport = [];
      this.columnDefs = [];
      this.rowData = [];
    },
    agrows() {
      // this.rows = [
      //   { name: "Parul Jain", noofsessions: 4, subject: "Maths" },
      //   { name: "Sumit Desai", noofsessions: 6, subject: "Physics" },
      //   { name: "Anil Shah", noofsessions: 19, subject: "Chemistry" },
      //   { name: "Nikita Jani", noofsessions: 4, subject: "Maths" },
      //   { name: "Jaymin Kulkarni", noofsessions: 6, subject: "Physics" },
      //   { name: "Loki Kanakaraj", noofsessions: 19, subject: "Chemistry" },
      //   { name: "Shafiq", noofsessions: 19, subject: "Biology" },
      //   { name: "Raja Babu", noofsessions: 62, subject: "Physics" },

      // ];
    },
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    // LinkCellRenderer,
  },
};
</script>

<style scoped>
.ag-theme-uex {
    /* --ag-background-color: #ddd; */
    /* --ag-odd-row-background-color: rgb(217, 188, 21); */
    /* --ag-alpine-active-color: red; */
    /* --ag-secondary-foreground-color: gold; */
    /* --ag-data-color:purple; */
    --ag-header-foreground-color:white;
    --ag-header-background-color:#6a2f85;
    /* --ag-selected-row-background-color:red; */
    /* --ag-modal-overlay-background-color:pink; */
    /* --ag-row-hover-color:black; */
    /* --ag-range-selection-border-color:red; */
    border-radius: 5px;
}
/* .ag-theme-alpine .ag-header-cell-label {
    font-style: italic;
    font-size:15px;
} */

  .uexhead {
    margin-top: 15px;
    font-size: 20px;
    cursor: pointer;
  }

  .uexheader {
    font-size: 20px;
    color: #642C90;
  }
   .uexdics {
    margin-top: -6.5px;
    border-radius: 1px solid;
    margin-bottom: 12.5px;
  }
.but1{
  background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
  border: #fff;
  width: 80px;
  height:25px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.csvbut{
  background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
    border-radius: 5px;
  color: #fff;
  border: #fff;
  width: 184px;
  height:29px;
  margin-bottom: 20px;
}
table.qtbl {
    border-collapse: separate;
    border-spacing: 0 10px;
}
table.qtbl thead tr {
    background-color: #6a2f85;
    color: #fff;
}
table.qtbl thead tr th {
    border-radius: 0;
}
  table.qtbl thead tr th:first-child, table.qtbl tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
}
  table.qtbl tbody tr {
    cursor: pointer;
    background-color: #fff;
    font-size: 14px;
    position: relative;
    border: none;
  }
table.qtbl thead tr th:last-child, table.qtbl tbody tr td:last-child {
    border-radius: 0 5px 5px 0;
}
table.qtbl tbody tr td {
    color: #6a2f85
}
  .uexbackbutton {
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
    border-radius: 5px;
    font-size: 14px;
    width: 130px;
  }
</style>
<style src="ag-grid-community/styles//ag-grid.css"></style>

<style src="ag-grid-community/styles//ag-theme-alpine.css"></style>
